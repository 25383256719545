import React from "react";
import { ConfigurationContext, ReviewContext } from "../CreateContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";

const ReviewState = ({ children }) => {
  // const reviewAPIUrl = process.env.REACT_APP_REVIEW_API_URL;
  const { configurationData } = useContext(ConfigurationContext);

  let reviewAPIUrl;
  if (configurationData) {
    reviewAPIUrl = `${configurationData.surl}/api/review`;
  } else {
    reviewAPIUrl = `${process.env.REACT_APP_SERVER_API_URL}/api/review`;
  }
  const [reviewData, setReviewData] = useState();

  const getAllReview = async () => {
    try {
      const response = await fetch(`${reviewAPIUrl}/getreview`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setReviewData(data);
        // console.log(data)
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // console.log(error.message);
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const DefaultObj = { reviewData, getAllReview };
  return (
    <ReviewContext.Provider value={DefaultObj}>
      {children}
    </ReviewContext.Provider>
  );
};

export default ReviewState;
