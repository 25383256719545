import React, { useContext } from "react";
import { useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { ConfigurationContext } from "../context/CreateContext";

const Page404 = ({title}) => {
  const navigate = useNavigate();

  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }

  useEffect(() => {
    document.title = `${title} | ${cname} `;
  });
  return (
    <>
      <section className="error-section p_relative d_block centred pt_140 pb_150">
        <div className="auto-container">
          <div className="inner-box">
            <figure className="error-image p_relative d_block mb_10">
              {/* <img src="assets/images/icons/error.png" alt="" /> */}
              <img src={"/assets/images/icon/error3.png"} style={{width : 444}} alt="" />
            </figure>
            <h2 className="d_block fs_50 lh_60 fw_bold mb_12">
              404 - Page Not Found
            </h2>
            <h3 className="d_block fs_24 fw_medium mb_55">
              The page you are looking for does not exist.
            </h3>
            <div className="text-box">
              <button onClick={()=>(navigate("/"))}  className="theme-btn theme-btn-one">
                <i className="far fa-long-arrow-left" /> Back to Homepage
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page404;
