import React, { useEffect, useState } from "react";
import Content from "../components/Banner_content";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import CloseIcon from "@mui/icons-material/Close";

import "./header/Header.css";
import { useContext } from "react";
import {
  ConfigurationContext,
  PositionContext,
} from "../context/CreateContext";
import { toast } from "react-toastify";
import { useRef } from "react";
// import { Fade } from "react-reveal";
import { Fade, Slide } from "react-reveal";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Career = ({ title }) => {
  const { getAllPosition, positionData, addApplicants, isLoading } =
    useContext(PositionContext);
  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  const [formData, setFormData] = useState({
    lname: "",
    fname: "",
    email: "",
    mno: "",
    description: "",
  });
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log(selectedFile);
    setFile(selectedFile);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log("formData--->", formData);
  };

  const validation = () => {
    const allowedExtensions = [".pdf"];
    if (formData.fname.length === 0) {
      return "First name is required";
    }
    if (!/^[a-zA-Z\s]*$/.test(formData.fname)) {
      return "Enter only text in firstname";
    }
    if (formData.lname.length === 0) {
      return "Last name is required";
    }
    if (!/^[a-zA-Z\s]*$/.test(formData.fname)) {
      return "Enter only text in firstname";
    }
    if (formData.email.length === 0) {
      return "Email is required";
    }
    if (
      !formData.email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      return "Please Enter Valid email";
    }
    if (formData.mno.length === 0) {
      return "Mobile number is required";
    }
    if (formData.mno.length !== 10) {
      return "Please Enter Minimum 10 Digits Number";
    }
    if (file == null) {
      return "Please Attech Resume File";
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes("." + fileExtension)) {
      return "Attech only .pdf";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const msg = validation(e);
    if (msg) {
      toast.error(`${msg}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const Data = new FormData();
      Data.append("resume", file);
      Data.append("lname", formData.lname);
      Data.append("fname", formData.fname);
      Data.append("description", formData.description);
      Data.append("email", formData.email);
      Data.append("mno", formData.mno);

      addApplicants(Data);
      setFile(null);
      setFormData({
        lname: "",
        fname: "",
        email: "",
        mno: "",
        description: "",
      });

      // console.log("Run");
    }
  };
  useEffect(() => {
    document.title = `${title} | ${cname} `;
    getAllPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <a href="#focusForm">Done</a> */}
      <Content title="Career" pathname="Career" />
      <div className="boxed-wrapper">
        <div className="service-one p_relative pt_50 mt_30">
          <div className="auto-container">
            <Fade top>
            <div className="sec-title p_relative d_block mb_50 mt_40 text-center">
              <h2 className="p_relative d_block fs_37 fw_bold mb_15">
                Join Our Beautiful Team
              </h2>
              <p className="d_block fs_17">
                  We are building a team of dreamers and doers who are
                  passionate about making a difference.
                  <br /> Are you with us ?
                </p>
            </div>
            </Fade>
            <div className="row clearfix  mx-3">
              <div className="col-lg-12 col-12">
                <div className="service-block-one">
                  <div className="d_block inner-box m_20 p_relative px_90 py_90 tran_5 rp">
                    {/* {positionData && positionData.length === 0 ? (
                      <h4 className="d_block fs_25 lh_30 mb_4 fw_sbold">
                        Currently any positon not open...
                      </h4>
                    ) : (
                      ""
                    )} */}
                    <Accordion
                      allowZeroExpanded
                      preExpanded={[0]}
                      className="border-0"
                    >
                      {isLoading ? (
                        <h4 className="d_block fs_25 lh_30 mb_4 fw_sbold">
                          Loading...
                        </h4>
                      ) : positionData && positionData.length === 0 ? (
                        <h4 className="d_block fs_25 lh_30 mb_4 fw_sbold">
                          Currently any positon not open...
                        </h4>
                      ) : (
                        positionData.map(
                          ({ pname, popen, expe, description }, i) => {
                            return (
                              <AccordionItem
                                className="mb_55 fs_20 border roundend-3"
                                key={i}
                                uuid={i}
                                style={{ borderRadius: "10px" }}
                              >
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <b className="mb_5 text-body">
                                      {pname} : {expe.split(":")[0].trim()} (
                                      {popen})
                                    </b>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p className="pb_10 mb_5 fw_sbold fs_18">
                                    Position : {popen}
                                  </p>
                                  <p className="pb_10 mb_5 fw_sbold fs_18">
                                    Experience : {expe.split(":")[1].trim()}
                                  </p>
                                  {description && (
                                    <p className="pb_15 mb_15 fw_sbold fs_18">
                                      {description}
                                    </p>
                                  )}

                                  {/* <div
                                        className="btn-box"
                                        // onClick={() => {
                                        //   inputRef.current.focus();
                                        // }}
                                      > */}
                                  <a
                                    href="#focusForm"
                                    className="d-inline-flex theme-btn theme-btn-one mb_25"
                                  >
                                    Apply Now
                                  </a>
                                  {/* </div> */}
                                </AccordionItemPanel>
                              </AccordionItem>
                            );
                          }
                        )
                      )}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            {/* Form */}
            <div className="boxed-wrapper" id="focusForm">
              <div className="service-one p_relative">
                <div className="auto-container row px_100">
                  <div className=" col-6">
                    <div className="content-box p_relative d_block z_1">
                      <Fade left>
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="fs_70 mt-4 lh_100">Speak to an</h2>
                          <h2 className="fs_70 mt-4 lh_100">expert</h2>
                        </div>
                      </Fade>
                      <Fade left>
                        <div
                          className="p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        ></div>
                      </Fade>
                      <div className="inner-box p_relative d_block wow fadeInUp pt_60 w-75 ">
                        <Slide left>
                          <div className="single-item p_relative d_block mb_16">
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our team can help you:
                            </h4>
                          </div>
                          <div>
                            <div className="d-flex">
                              <CheckCircleOutlineRoundedIcon
                                style={{
                                  color: "#05683B",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                              {/* <img
                                src={"/assets/images/img/arrow-right.png"}
                                alt="CHECK_MARK"
                                className="h-100 pt-2 w_30"
                              /> */}
                              <div className="pl-3">
                                By listening to your app challenges and provide{" "}
                                <br />
                                solutions.
                              </div>
                            </div>
                            <div className="d-flex">
                              <CheckCircleOutlineRoundedIcon
                                style={{
                                  color: "#05683B",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                              <div className="pl-3">
                                Consult on best practices for making your new
                                app product a success.
                              </div>
                            </div>
                            <div className="d-flex">
                              <CheckCircleOutlineRoundedIcon
                                style={{
                                  color: "#05683B",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                              <div className="pl-3">
                                With guidance on best practices for your AWS
                                infrastructure security and scalability.
                              </div>
                            </div>
                            <div className="d-flex">
                              <CheckCircleOutlineRoundedIcon
                                style={{
                                  color: "#05683B",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                              <div className="pl-3">
                                Design a software solution that meet your
                                business needs.
                              </div>
                            </div>
                          </div>
                        </Slide>
                      </div>

                      <div className="mt-5">
                        <div className="fs_50 fw_medium mb-5 text-black">
                          You’re in good hands
                        </div>
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: "26px" }}
                        >
                          <img
                            src={"/assets/images/img/achd.svg"}
                            alt="BANNER-1"
                            style={{ width: "150px" }}
                          />
                          <img
                            src={"/assets/images/img/veeps.svg"}
                            alt="BANNER-1"
                            style={{ width: "150px" }}
                          />
                          <img
                            src={"/assets/images/img/tamarack.svg"}
                            alt="BANNER-1"
                            style={{ width: "150px" }}
                          />
                          <img
                            src={"/assets/images/img/live.svg"}
                            alt="BANNER-1"
                            style={{ width: "150px" }}
                          />
                          <img
                            src={"/assets/images/img/pga.svg"}
                            alt="BANNER-1"
                            style={{ width: "150px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-6">
                    <div className="boxed-wrapper" id="focusForm">
                      <div className="service-one p_relative">
                        <div className="auto-container">
                          <div className="row clearfix  mx-3">
                            <div className="col-lg-12 col-12">
                              <div className="service-block-one">
                                <div
                                  className="centred d_block inner-box mt-4 p_relative px-5 py-5 tran_5"
                                  style={{
                                    borderRadius: "20px",
                                    backgroundImage:
                                      "radial-gradient(circle farthest-side at -90% 80%, #c0dec1 18%, rgba(255, 255, 255, 0) 76%)",
                                  }}
                                >
                                  <div role="form">
                                    <div className="row justify-content-between">
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              First Name
                                            </p>
                                            <input
                                              type="text"
                                              className="form-control mb_30 p_30"
                                              autoComplete="off"
                                              name="fname"
                                              id="firstname"
                                              ref={inputRef}
                                              required=""
                                              value={formData.fname}
                                              onChange={handleChange}
                                              placeholder="John"
                                            />
                                          </div>
                                        </div>
                                      </Fade>
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              Last Name
                                            </p>
                                            <input
                                              type="text"
                                              className="form-control mb_30 p_30"
                                              autoComplete="off"
                                              name="lname"
                                              id="lastname"
                                              value={formData.lname}
                                              onChange={handleChange}
                                              placeholder="Doe"
                                            />
                                          </div>
                                        </div>
                                      </Fade>
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              Work Email
                                            </p>
                                            <input
                                              type="text"
                                              className="form-control mb_30 p_30"
                                              autoComplete="off"
                                              name="email"
                                              id="email"
                                              required=""
                                              value={formData.email}
                                              onChange={handleChange}
                                              placeholder="john@example.com"
                                            />
                                          </div>
                                        </div>
                                      </Fade>
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              Work Phone
                                            </p>
                                            <input
                                              type="text"
                                              className="form-control mb_30 p_30"
                                              name="mno"
                                              required=""
                                              value={formData.mno}
                                              onInput={(e) =>
                                                (e.target.value = e.target.value
                                                  .replace(/[^0-9.]/g, "")
                                                  .replace(/(\..*?)\..*/g, "$1")
                                                  .slice(0, 10))
                                              }
                                              onChange={handleChange}
                                              placeholder="55555 55555"
                                            />
                                          </div>
                                        </div>
                                      </Fade>
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              Document
                                            </p>
                                            {!file ? (
                                              <input
                                                type="file"
                                                className="form-control-file mb_30 p_12"
                                                name="resume"
                                                key={Date.now()}
                                                accept=".pdf"
                                                onChange={handleFileChange}
                                                placeholder="resume.pdf"
                                              />
                                            ) : (
                                              <div className="form-control-file mb_30 p_16 border d-flex justify-content-between ">
                                                <div>{file.name}</div>
                                                <button
                                                  onClick={() => {
                                                    setFile(null);
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Fade>
                                      <Fade right>
                                        <div className="col-lg-12 col-12">
                                          <div className="d-flex">
                                            <p className="align-content-center fs_20 fw_sbold mb_30 text-dark text-left w-50 p-0">
                                              Description
                                            </p>
                                            <textarea
                                              className="form-control mb_30 p_12"
                                              id="description"
                                              name="description"
                                              placeholder="Tell Us Something About You"
                                              rows="4"
                                              value={formData.description}
                                              onChange={handleChange}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </Fade>

                                      <div className="col-12 mx-auto">
                                        <a
                                          href={"/"}
                                          className="d-inline-flex theme-btn theme-btn-one centred"
                                          onClick={handleSubmit}
                                        >
                                          Submit
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
