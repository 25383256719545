import React from "react";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { ConfigurationContext } from "../../context/CreateContext";

const Footer = () => {
  // const COMPANY_SITE = process.env.REACT_APP_COMPANY_SITE;
  const { configurationData } = useContext(ConfigurationContext);

  let COMPANY_SITE;

  if (configurationData) {
    COMPANY_SITE = `${configurationData.cname}`;
  } else {
    COMPANY_SITE = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  const location = useLocation();

  return (
    <>
      <div className="boxed-wrapper">
        <footer className="footer-one p_relative">
          <div className="footer-widget-section p_relative">
            <div className="auto-container">
              <div className="wid">
                <Link to={"/"}>
                  <img
                    src={"/assets/images/logo/footer_logo.png"}
                    // src={"/assets/images/logo/Footer-Logo.png"}
                    alt=""
                    className="mb-5"
                  />
                </Link>
              </div>
              <div className="linkin fs_18">
                {/* <Link to="/">Home</Link> */}
                <Link
                  to={"/"}
                  className={location.pathname === "/" ? "active" : ""}
                >
                  Home
                </Link>
                <span className="stand">|</span>
                <Link
                  to={"/about"}
                  className={location.pathname === "/about" ? "active" : ""}
                >
                  The Company
                </Link>{" "}
                <span className="stand">|</span>
                <Link
                  to={"/service"}
                  className={location.pathname === "/service" ? "active" : ""}
                >
                  Services
                </Link>{" "}
                <span className="stand">|</span>
                <Link
                  to={"/career"}
                  className={location.pathname === "/career" ? "active" : ""}
                >
                  Careers
                </Link>{" "}
                <span className="stand">|</span>                
                <Link
                  to={"/contact"}
                  className={location.pathname === "/contact" ? "active" : ""}
                >
                  Contact us
                </Link>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="border-top opacity-25 pt_25 pb_25">
                <Link to={"/"} className="text-white fs_18">
                  {" "}
                  &copy; {new Date().getFullYear()} {COMPANY_SITE}{" "}
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
