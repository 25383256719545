import React from "react";
import "./header/Header.css";
import { Link } from "react-router-dom";
import { Bounce, Fade } from "react-reveal";

const Content = (props) => {
  return (
    <>
        <div className="boxed-wrapper">
          <div className="p_relative pt_140 pb_150 centred">
            <div className="bg-layer p_absolute parallax_none content_img"></div>
            <div className="auto-container">
              <div className="inner-box p_relative about_c">
      <Fade top  >
                <h1 className="d_block fs_37 fw_bold">{props.title}</h1>
      </Fade>
      <Fade bottom >
                <p className="fs_20 mt-4 content_a">
                  <Link to="/">Home / {props.pathname}</Link>
                </p>
      </Fade>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Content;
