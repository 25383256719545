import React from "react";
import "./Header.css";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { ConfigurationContext } from "../../context/CreateContext";
import { motion, useScroll } from "framer-motion";

const Header = () => {
  const location = useLocation();

  // const COMPANY_SITE = process.env.REACT_APP_COMPANY_SITE;
  // const HR_MAIL = process.env.REACT_APP_HR_MAIL;
  // const HR_NUMBER = process.env.REACT_APP_HR_NUMBER;
  // const COMPANY_ADDRESS = process.env.REACT_APP_ADDRESS;
  // const TEL = process.env.REACT_APP_TEL;
  // const HR_MAILTO = process.env.REACT_APP_MAILTO;
  const { configurationData } = useContext(ConfigurationContext);

  let HR_MAIL;
  // let ADMIN_MAIL;
  // let ADMIN_NUMBER;
  let COMPANY_ADDRESS;
  let HR_MAILTO;
  let COMPANY_SITE;
  let HR_NUMBER;

  if (configurationData) {
    HR_MAIL = `${configurationData.hrmail}`;
    // ADMIN_MAIL = `${configurationData.amail}`;
    // ADMIN_NUMBER = `${configurationData.anumber}`;
    COMPANY_ADDRESS = `${configurationData.address}`;
    HR_MAILTO = `mailto:${configurationData.hrmail}`;
    COMPANY_SITE = `${configurationData.cname}`;
    HR_NUMBER = `${configurationData.hrnumber}`;
  } else {
    HR_MAIL = `${process.env.REACT_APP_HR_MAIL}`;
    HR_MAILTO = `mailto:${process.env.REACT_APP_HR_MAIL}`;
    // ADMIN_MAIL = `${process.env.REACT_APP_ADMIN_MAIL}`;
    // ADMIN_NUMBER = `${process.env.REACT_APP_ADMIN_NUMBER}`;
    COMPANY_ADDRESS = `${process.env.REACT_APP_ADDRESS}`;
    COMPANY_SITE = `${process.env.REACT_APP_COMPANY_SITE}`;
    HR_NUMBER = `${process.env.REACT_APP_HR_NUMBER}`;
  }

  const handleInput = () => {
    if ($(".mobile-menu").length) {
      $(".sticky-header .main-menu").empty();

      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      $(".mobile-menu .menu-backdrop,.mobile-menu .close-btn , .home-menu").on(
        "click",
        function () {
          $("body").removeClass("mobile-menu-visible");
        }
      );
      // console.log("mobileMenuContent--->", mobileMenuContent);
    }
  };
  const { scrollYProgress } = useScroll();

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return (
    <>
      {/* <motion.div style={{ scaleX: scrollYProgress }} /> */}
      <link href="/assets/css/font-awesome-all.css" rel="stylesheet" />
      <link
        href="/assets/font/inter/static/inter-Regular.ttf"
        rel="stylesheet"
      />
      <link href="/assets/css/flaticon.css" rel="stylesheet" />
      <link href="/assets/css/owl.css" rel="stylesheet" />
      <link href="/assets/css/bootstrap.css" rel="stylesheet" />
      <link href="/assets/css/jquery.fancybox.min.css" rel="stylesheet" />
      <link href="/assets/css/animate.css" rel="stylesheet" />
      <link href="/assets/css/color.css" rel="stylesheet" />
      <link href="/assets/css/global.css" rel="stylesheet" />
      <link href="/assets/css/elpath.css" rel="stylesheet" />
      <link href="/assets/css/style.css" rel="stylesheet" />
      <link href="/assets/css/responsive.css" rel="stylesheet" />

      <div
        className="boxed-wrapper"
        style={{
          position: "sticky",
          top: "0",
          zIndex: 1000,
          background: "white",
        }}
      >
        <header className="main-header">
          <div className="header-top-one p_relative d_block border-bottom pb_5">
            <div className="auto-container">
              <div className="top-inner clearfix">
                <div className="top-left pull-left">
                  <p className="pt_11">
                    Welcome to <span className="fs_16 ">{COMPANY_SITE}</span>
                  </p>
                </div>
                <div className="top-right pull-right mt_8">
                  <ul className="info clearfix top-right pull-right">
                    <li className="d_iblock float_left lh_30 pl_25 fs_16">
                      <p className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 28.5 24.5"
                        >
                          <defs>
                            <style>.cls-1{"fill#a6a6a6"}</style>
                          </defs>
                          <title>mail_1</title>
                          <path
                            className="cls-1"
                            d="M23.25,0h-18A5.25,5.25,0,0,0,0,5.25v14A5.26,5.26,0,0,0,5.25,24.5h18a5.26,5.26,0,0,0,5.25-5.25v-14A5.26,5.26,0,0,0,23.25,0Zm-18,2.5h18A2.75,2.75,0,0,1,26,5.25v.3L14.25,12.78,2.5,5.55v-.3A2.75,2.75,0,0,1,5.25,2.5Zm18,19.5h-18A2.75,2.75,0,0,1,2.5,19.25V8.49l11.09,6.82a1.26,1.26,0,0,0,1.32,0L26,8.49V19.25A2.75,2.75,0,0,1,23.25,22Z"
                          />
                        </svg>
                        <Link to={HR_MAILTO}> {HR_MAIL}</Link>
                      </p>
                    </li>
                    <li className="d_iblock lh_30 line"></li>
                    <li className=" d_iblock float_left mr_3 lh_50 pl_25 fs_16">
                      <p className="pl_30 _pr">
                        <svg
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 402.04 402.04"
                        >
                          <title>call</title>
                          <g id="_1" data-name=" 1">
                            <path d="M294.64,402a198.8,198.8,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C-7.44,98.73,2.06,51.59,32.6,21.05l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L280,251a42.15,42.15,0,0,1,59.61,0h0l50.1,50.1a42.17,42.17,0,0,1,0,59.61L381,369.41C359.44,391,329.64,402,294.64,402ZM71.13,30a12,12,0,0,0-8.59,3.56l-8.73,8.72C30.94,65.13,24.26,102.26,35,146.75c11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.17,12.17,0,0,0,0-17.19l-50.09-50.1a12.17,12.17,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-67s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.17,12.17,0,0,0,0-17.19L79.73,33.54A12.09,12.09,0,0,0,71.13,30Z" />
                            <path d="M310.66,221.71a15,15,0,0,1-15-15h0a99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30h0c71.27,0,129.25,58,129.25,129.26a15,15,0,0,1-15,15Z" />
                            <path d="M374.06,221.71a15,15,0,0,1-15-15h0c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30h0c106.23,0,192.65,86.43,192.65,192.66a15,15,0,0,1-15,15Z" />
                          </g>
                        </svg>
                        <Link to={`tel:${HR_NUMBER}`}> {HR_NUMBER} </Link>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-lower">
            <div className="auto-container p-0">
              <div className="outer-box">
                <div className="logo-box">
                <figure className="logo">
                    <Link to={"/"}>
                      {/* <img src={"/assets/images/logo/Possibility-Wave-Logo-01.png"} alt="LOGO" /> */}
                      <img
                        src={
                          "/assets/images/logo/Possibility-Wave-Logo-260x185 (Final)-01.png"
                        }
                        alt="LOGO"
                      />
                    </Link>
                  </figure>
                </div>
                <div className="menu-area clearfix">
                  <div className="mobile-nav-toggler" onClick={handleInput}>
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                  </div>
                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div
                      className="collapse navbar-collapse show clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix home-menu">
                        <li
                          className={location.pathname === "/" ? "current" : ""}
                        >
                          {<Link to={"/"}>Home</Link>}
                        </li>
                        <li
                          className={
                            location.pathname === "/about" ? "current" : ""
                          }
                        >
                          {<Link to={"/about"}>The Company</Link>}
                        </li>
                        <li
                          className={
                            location.pathname === "/service" ? "current" : ""
                          }
                        >
                          {<Link to={"/service"}>Services</Link>}
                        </li>
                        <li
                          className={
                            location.pathname === "/career" ? "current" : ""
                          }
                        >
                          {<Link to={"/career"}>Careers</Link>}
                        </li>
                        <li
                          className={
                            location.pathname === "/contact" ? "current" : ""
                          }
                        >
                          {<Link to={"/contact"}>Contact us</Link>}
                        </li>
                        <li
                          className={
                            location.pathname === "/portfolio" ? "current" : ""
                          }
                        >
                          {<Link to={"/portfolio"}>Portfolio</Link>}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="btn-box d-block">
                  <Link to={"/contact"} className="theme-btn theme-btn-one">
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* sticky-header */}
          <div className="sticky-header">
            <div className="auto-container">
              <div className="outer-box">
                <div className="menu-area clearfix">
                  <nav className="main-menu clearfix"></nav>
                </div>
                <div className="btn-box">
                  <Link to={"/"} className="theme-btn theme-btn-one">
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn">
            <i className="fas fa-times"></i>
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
              <Link to={"/"}>
              <img
                  src={
                    "/assets/images/logo/Possibility-Wave-Logo-260x185 (Final)-01.png"
                  }
                  title=""
                />
              </Link>
            </div>
            <div className="menu-outer">
              {/* Here Menu Will Come Automatically Via Javascript / Same Menu as in Header */}
              <div
                className="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix home-menu">
                  <li className={location.pathname === "/" ? "current" : ""}>
                    {<Link to={"/"}>Home</Link>}
                  </li>
                  <li
                    className={location.pathname === "/about" ? "current" : ""}
                  >
                    {<Link to={"/about"}>The Company</Link>}
                  </li>
                  <li
                    className={
                      location.pathname === "/service" ? "current" : ""
                    }
                  >
                    {<Link to={"/service"}>Services</Link>}
                  </li>
                  <li
                    className={location.pathname === "/career" ? "current" : ""}
                  >
                    {<Link to={"/career"}>Careers</Link>}
                  </li>                  
                  <li
                    className={
                      location.pathname === "/contact" ? "current" : ""
                    }
                  >
                    {<Link to={"/contact"}>Contact us</Link>}
                  </li>
                  <li
                    className={
                      location.pathname === "/portfolio" ? "current" : ""
                    }
                  >
                    {<Link to={"/portfolio"}>Portfolio</Link>}
                  </li>
                </ul>
              </div>
            </div>
            <div className="contact-info">
              <h4>Contact Info</h4>
              <ul>
                <li>{COMPANY_ADDRESS}</li>
                <li>
                  <Link to={`tel:${HR_NUMBER}`}>{`+1 ${HR_NUMBER}`}</Link>
                </li>
                <li>
                  <Link to={HR_MAILTO}> {HR_MAIL}</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <script src="/assets/js/jquery.js"></script>
      <script src="/assets/js/popper.min.js"></script>
      <script src="/assets/js/bootstrap.min.js"></script>
      <script src="/assets/js/plugins.js"></script>
      <script src="/assets/js/owl.js"></script>
      <script src="/assets/js/wow.js"></script>
      <script src="/assets/js/validation.js"></script>
      <script src="/assets/js/jquery.fancybox.js"></script>
      <script src="/assets/js/appear.js"></script>
      <script src="/assets/js/scrollbar.js"></script>
      <script src="/assets/js/parallax.min.js"></script>
      <script src="/assets/js/circle-progress.js"></script>
      <script src="/assets/js/jquery.countTo.js"></script>
      <script src="/assets/js/jquery.nice-select.min.js"></script>
      <script src="/assets/js/parallax-scroll.js"></script>

      <script src="/assets/js/script.js"></script>
    </>
  );
};
export default Header;
