import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./header/Header.css";
// import "react-multi-carousel/lib/styles.css";
// import logo from "../assets/image/icon/Android.png";
// import  Carousel from "react-multi-carousel";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ConfigurationContext, ReviewContext } from "../context/CreateContext";
import { useEffect } from "react";
import ClientLogo from "../components/Client_logo";
// import { InView, useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
import Jello from "react-reveal/Jello";
import CountUp from "react-countup";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
// import { motion, useScroll } from "framer-motion";

const Home = ({ title }) => {
  // const PublicImageURL = process.env.REACT_APP_PUBLIC_IMAGE_URL
  const { configurationData } = useContext(ConfigurationContext);

  let PublicImageURL;
  let cname;

  if (configurationData) {
    PublicImageURL = `${configurationData.surl}/img`;
    cname = `${configurationData.cname}`;
  } else {
    PublicImageURL = `${process.env.REACT_APP_SERVER_API_URL}/img`;
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  const { getAllReview, reviewData } = useContext(ReviewContext);

  useEffect(() => {
    document.title = `${title} | ${cname} `;
    getAllReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Animation Code
  // const { scrollYProgress } = useScroll();

  return (
    <>
      {/* <motion.div
        style={{
          scaleX: scrollYProgress,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "10px",
          background: "red",
          transformOrigin: "0%",
        }}
      /> */}
      <div className="boxed-wrapper">
        <header className="main-header">
          <div className="header-top-one p_relative d_block border-bottom">
            <div className="" style={{filter : "blur(3px)"}}>
              <img
                src={"/assets/images/banner/Banner-1.jpg"}
                width={"100"}
                height={100}
                alt="BANNER-1"
              />
            </div>
            <div className="auto-container">
              <Fade left>
                <div className="col-12 main_banner p_relative">
                  <h5 className="_h5">
                    Empowering businesses in the alternative health, finance,
                    and gift card industries with cutting-edge mobile
                    applications and expert database consulting.
                  </h5>
                  <h1 className="mb-5 _h1">
                    Unlocking Potential Through Innovative Mobile Solutions
                  </h1>
                  <div className="btn-box pt_10">
                    <Link
                      to={"/about"}
                      className="theme-btn theme-btn-one button _btn"
                    >
                      About More
                    </Link>
                  </div>
                </div>
              </Fade>
              {/* <Fade top>
                <div
                  className="sec-title p_relative d_block mt_60 pt_55 text-center"
                  id="box1"
                >
                  <h2 className="p_relative d_block fs_42 fw_bold mb_18 ">
                    They say it’s not the destination, it’s the journey
                  </h2>
                  <p className="d_block">
                    There’s certainly some truth in that. While a premium
                    product is no doubt where we intend to land, that product is
                    simply <br /> the outcome of a premium process driven by
                    people who care as much about your business as you do.
                  </p>
                </div>
              </Fade> */}
              <div className="inner-container sec-pad p_relative mb_36">
                <div className="row align-items-center clearfix ">
                  <Fade left cascade>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column animate__backInRight">
                      {/* <div className="col-lg-6 col-md-12 col-sm-12 content-column" id="box4"> */}
                      <div className="content_block_three">
                        <div className="p_relative d_block">
                          <div className="sec-title mx-3 p_relative d_block">
                            <ul>
                              <li style={{listStyle: "none"}} className="ml-0">
                                <h2 className="p_relative d_block fw_xbold mb-4 ">
                                They say it’s not the <br/> destination, it’s the journey
                                </h2>
                              </li>
                            </ul>
                          </div>
                          <div className="p_relative d_block mb_30 wow">
                            <p className="_pb fs_18 lh_30 py-1">
                              We don’t just build. We collaborate to determine
                              the most efficient software solution to solve
                              problems, take time to understand the vision, and
                              work closely with you to build a valuable
                              solution.
                            </p>

                            <p className="_pb fs_18 lh_30 py-1">
                              Our aim for every client is to build a long-term
                              relationship and be an extension of your team.
                            </p>

                            <p className="_pb fs_18 lh_30 py-1">
                              Welcome to Possibility Wave.
                            </p>

                            {/* <p className="_pb fs_18 lh_30">
                              Keep scrolling to learn more about our services
                              and clients.
                            </p> */}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </Fade>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image_block_three">
                      <Fade right>
                        <div className="image-box d_block">
                          {/* <figure className="image p_relative d_block"> */}
                          <figure className="d-flex justify-content-center">
                            <img
                              src={"/assets/images/img/aws_partner.webp"}
                              alt="WHO_WE_ARE_IMAGE"
                              className=" ml-4"
                              style={{height:"499px" , width: "529px"}}
                            />
                          </figure>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* -------------------- */}

            <div className="funfact-one pt_148 pb_148 text-center p_relative">
              <div>
                <div className="p_absolute"></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix">
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        {/* <PermIdentityIcon
                          style={{
                            color: "#0d431e",
                            background: "white",
                            borderRadius: "50px",
                            width: "45px",
                            height: "45px",
                            padding: "8px",
                          }}
                        /> */}

                        <img
                          src={"/assets/images/icon/clients.svg"}
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          <CountUp end={300} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Our Satisfied Clients{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        {/* <AccountTreeRoundedIcon
                          style={{
                            color: "#0d431e",
                            background: "white",
                            borderRadius: "50px",
                            width: "45px",
                            height: "45px",
                            padding: "8px",
                          }}
                        /> */}
                        <img
                          src={"/assets/images/icon/projects.svg"}
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">465</span> */}
                          <CountUp end={465} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Project Completed{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        {/* <HourglassEmptyRoundedIcon
                          style={{
                            color: "#0d431e",
                            background: "white",
                            borderRadius: "50px",
                            width: "45px",
                            height: "45px",
                            padding: "8px",
                          }}
                        /> */}
                        <img
                          src={"/assets/images/icon/hours.svg"}
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">9000+</span> */}
                          <CountUp end={9000} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Total Working Hours{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        {/* <MilitaryTechIcon
                          style={{
                            color: "#0d431e",
                            background: "white",
                            borderRadius: "50px",
                            width: "45px",
                            height: "45px",
                            padding: "8px",
                          }}
                        /> */}
                        <img
                          src={"/assets/images/icon/award.svg"}
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">36</span> */}
                          <CountUp end={36} enableScrollSpy={true} />
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Awarded Winning
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------ */}

            <div className="service-one p_relative sec-pad">
              <div className="auto-container r1">
                {/* <motion.div
                  initial={box2.initial}
                  animate={box2.controls}
                  transition={box2.transition}
                > */}
                <Fade top>
                  <div
                    className="p_relative d_block mb_50 mt-4 pb-4 text-center"
                    id="box2"
                  >
                    <h2 className="p_relative d_block fs_42">Our Services</h2>
                    <p className="d_block fs_17 mt-4">
                      Our services are designed to provide the best possible
                      solutions for our clients.
                    </p>
                  </div>
                </Fade>
                {/* </motion.div> */}
                <div className="container-fluid">
                  <div className="row clearfix pt_30 pb-4 justify-content-md-center ">
                    <Jello>
                      <div className="col-lg-5 col-xl-4 col-md-7 col-sm-12 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/mobile_application_new.svg"
                                  }
                                  alt="MOBILE_APPLICATION"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Mobile Application</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string.slice(0, 90) : string}
                              {string.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                We develop native mobile apps for Android and
                                iOS that are fast, user-friendly and tailored to
                                your specific needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                    <Jello>
                      <div className="col-lg-5 col-md-7 col-xl-4 col-sm-12 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/web_cms_ecommerce.svg"
                                  }
                                  alt="WEB_CMS_ECOMMERCE"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Web/CMS & Ecommerce</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string1.slice(0, 90) : string1}
                              {string1.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                Our team of experts has in-depth knowledge of
                                the latest web development technologies, so we
                                can create a website that is both visually
                                appealing and functional.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                    <Jello>
                      <div className="col-lg-5 col-md-7 col-sm-12 col-xl-4 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/graphics_designing.svg"
                                  }
                                  alt="GRAPHICS_DESIGNING"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Graphics Designing</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string2.slice(0, 90) : string2}
                              {string2.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                From logos to social media graphics, we can
                                create custom graphics that make your brand
                                stand out.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta-one p_relative pt_130 pb_140 centred ">
              <div className="bg-layer p_absolute l_0 parallax_none parallax-bg get_img"></div>
              <div className="auto-container">
                <div className="inner-box p_relative z_1">
                  <Fade right>
                    <h2 className="d_block fs_40 fw_bold mb_20">
                      Get In Touch
                    </h2>
                  </Fade>
                  <Fade>
                    <p className="fs_16 mb_30">
                      Do you need a Digital BOOST? <br /> For more information
                      about our services and how we can help you achieve your
                      goals, contact us now. <br />
                      PS: We offer a free consultation to discuss your needs
                    </p>
                  </Fade>
                  <Fade right cascade>
                    <h6 className="mb_30 fs_20 fw_sbold mb-lg-5">
                      " Say Hello! Don't Be Shy. "
                    </h6>
                  </Fade>
                  <Link
                    to="/contact"
                    className="theme-btn theme-btn-one con_btn"
                  >
                    <span data-text="Contact Us">Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>

            {reviewData && reviewData.length !== 0 ? (
              <div>
                {/* <motion.div
                  initial={box3.initial}
                  animate={box3.controls}
                  transition={box3.transition}
                > */}
                <Fade top>
                  <div
                    className="sec-title p_relative d_block mb_35 text-center z_1 pb_10"
                    id="box3"
                  >
                    <h2 className="p_relative d_block fs_42 fw_bold mb_10 pt_130">
                      Our Clients Reviews
                    </h2>
                    <p>
                      I have a remote team that I can't buy a beer for in
                      person. This is an awesome way to
                      <br /> send my appreciation and build a cohesive company
                      culture with my staff!
                    </p>
                  </div>
                </Fade>
                {/* </motion.div> */}

                <Carousel
                  showArrows={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  interval={2000}
                >
                  {reviewData &&
                    reviewData.map(
                      ({ c_name, c_rating, c_review, avatar }, i) => {
                        return (
                          <div className="mb_50 pb_40" key={i}>
                            <img
                              src={`${PublicImageURL}/client/${avatar}`}
                              alt="SLIDE_1"
                            />
                            <div className="myCarousel">
                              <h3 className="mt-3">{c_name}</h3>
                              <ul className="list-unstyled d-flex justify-content-center text-warning fs_19 mt-4">
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        1 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        2 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        3 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        4 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        5 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                              </ul>
                              <p>"{c_review}"</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                </Carousel>
              </div>
            ) : (
              <h4 className="text-center mt-5">Not Active Any Review</h4>
            )}
            <ClientLogo />
          </div>
        </header>
      </div>
    </>
  );
};

export default Home;
