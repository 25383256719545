import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { useEffect } from "react";

const Layout = ({ Componets, title }) => {
    useEffect(() => {
    // console.log("Layout")
    }, []);
  return (
    <>
      <Header />
      <Componets title={title} />
      <Footer />
    </>
  );
};

export default Layout;
