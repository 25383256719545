import React from "react";
import "./header/Header.css";
import Content from "./Banner_content";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import { Fade, Slide } from "react-reveal";

const Service = ({ title }) => {
  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  useEffect(() => {
    document.title = `${title} | ${cname} `;
  });
  return (
    <>
      <Content title="Services" pathname="Services" />
      <div className="boxed-wrapper mx-3">
        <div className="p_relative pb_10 mb_10 pt_10 mt_70">
          <div className="auto-container">
            <div className="inner-container p_relative">
              {/* desk-Content */}
              <div className="desk-Content">
                {/* Mobile App Development */}
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                      <div className="image_block_two">
                        <div className="p_relative d_block">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/app_devlopment.svg"}
                              alt="WEB_CMS_ECOMMERCE"
                              className="web_img "
                            />
                          </figure>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <Fade right>
                            <h2 className="p_relative d_block fs_40 fw_bold">
                              Mobile App Development
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_18"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Native App Development
                              </h4>
                              <p className="p-0">
                                Building mobile applications specifically for a
                                single platform (iOS or Android) using
                                platform-specific programming languages (Swift
                                or Objective-C for iOS, Java or Kotlin for
                                Android).
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Cross-Platform App Development
                              </h4>
                              <p className="p-0">
                                Creating mobile apps that can run on multiple
                                platforms using frameworks like React Native,
                                Flutter, or Xamarin.
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Hybrid App Development
                              </h4>
                              <p className="p-0">
                                Developing apps that combine elements of both
                                native and web applications, often using web
                                technologies like HTML, CSS, and JavaScript
                                wrapped in a native container.
                              </p>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Web Development */}
                <div className="chooseus-one p_relative pt_10 mt_10">
                  <div className="auto-container">
                    <div className="inner-container p_relative pb_20 mb_10">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                          <div className="content-box p_relative d_block z_1 mr-4">
                            <Fade left>
                              <div className="sec-title p_relative d_block pmtb">
                                <h2 className="p_relative d_block fs_35 fw_bold mt_20 pt_50">
                                  Web Development
                                </h2>
                              </div>
                            </Fade>
                            <div
                              className="inner-box p_relative d_block wow fadeInUp pt_20"
                              data-wow-duration="1500ms"
                            >
                              <Slide left>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Front-End Development
                                  </h4>
                                  <p className="p-0">
                                    Designing and building the user interface
                                    (UI) and user experience (UX) components of
                                    websites using technologies like HTML, CSS,
                                    and JavaScript
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Back-End Development
                                  </h4>
                                  <p className="p-0">
                                    Developing the server-side logic, databases,
                                    and APIs that power web applications, often
                                    using languages and frameworks like Node.js,
                                    Python (Django or Flask), Ruby on Rails, or
                                    PHP (Laravel or Symfony)
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Full-Stack Development
                                  </h4>
                                  <p className="p-0">
                                    Providing end-to-end development services,
                                    encompassing both front-end and back-end
                                    development, to create fully functional web
                                    applications
                                  </p>
                                </div>
                              </Slide>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                          <Fade right>
                            <div className="image_block_two">
                              <div className="image-box p_relative d_block">
                                <figure className="image p_relative d_block">
                                  <img
                                    src={
                                      "/assets/images/img/web_development.svg"
                                    }
                                    alt="MOBILE_APPLICATION"
                                    className="mob_img mt-5 pt-5"
                                  />
                                </figure>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Database Management */}
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                      <div className="image_block_two">
                        <div className="p_relative d_block">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/server.svg"}
                              alt="WEB_CMS_ECOMMERCE"
                              className="web_img "
                            />
                          </figure>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <Fade right>
                          <div className="sec-title p_relative d_block mb_20">
                            <h2 className="p_relative d_block fs_40 fw_bold">
                              Database Management
                            </h2>
                          </div>
                        </Fade>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_10"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Database Design and Architecture
                              </h4>
                              <p className="p-0">
                                Designing the structure and architecture of
                                databases to efficiently store, organize, and
                                retrieve data, taking into account factors like
                                scalability, performance, and security
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Database Development
                              </h4>
                              <p className="p-0">
                                Developing and implementing database solutions
                                tailored to the specific needs of clients,
                                including creating database schemas, writing SQL
                                queries, and optimizing database performance
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Database Administration
                              </h4>
                              <p className="p-0">
                                Managing and maintaining databases to ensure
                                ongoing reliability, security, and performance,
                                including tasks such as backup and recovery,
                                data integrity, and user access control
                              </p>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Software Architecture */}
                <div className="chooseus-one p_relative pt_10 mt_10">
                  <div className="auto-container">
                    <div className="inner-container p_relative pb_20 mb_10">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                          <div className="content-box p_relative d_block z_1 mr-4">
                            <Fade left>
                              <div className="sec-title p_relative d_block pmtb">
                                <h2 className="p_relative d_block fs_35 fw_bold mt_20 pt_50">
                                  Software Architecture
                                </h2>
                              </div>
                            </Fade>
                            <div
                              className="inner-box p_relative d_block wow fadeInUp pt_20"
                              data-wow-duration="1500ms"
                            >
                              <Slide left>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Understand the Requirements
                                  </h4>
                                  <p className="p-0">
                                    Begin by thoroughly understanding the
                                    functional and non-functional requirements
                                    of the software. This includes user needs,
                                    business goals, performance expectations,
                                    and scalability requirements
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Identify Key Stakeholders
                                  </h4>
                                  <p className="p-0">
                                    Identify and involve all relevant
                                    stakeholders, including end-users, product
                                    managers, developers, and quality assurance
                                    teams, to gather input and insights
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Define Architecture Objectives
                                  </h4>
                                  <p className="p-0">
                                    Establish clear architecture objectives that
                                    align with the project's goals. These
                                    objectives should guide your architectural
                                    decisions
                                  </p>
                                </div>
                              </Slide>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                          <Fade right>
                            <div className="image_block_two">
                              <div className="image-box p_relative d_block">
                                <figure className="image p_relative d_block">
                                  <img
                                    src={
                                      "/assets/images/img/software_arch.svg"
                                    }
                                    alt="MOBILE_APPLICATION"
                                    className="mob_img mt-5 pt-5"
                                  />
                                </figure>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Dev Ops  */}
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                      <div className="image_block_two">
                        <div className="p_relative d_block">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/devvoops.svg"}
                              alt="WEB_CMS_ECOMMERCE"
                              className="web_img "
                            />
                          </figure>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <Fade right>
                          <div className="sec-title p_relative d_block mb_20">
                            <h2 className="p_relative d_block fs_40 fw_bold">
                              Dev Ops
                            </h2>
                          </div>
                        </Fade>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_10"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Assessment and Planning
                              </h4>
                              <p className="p-0">
                                Begin by assessing your current development and
                                operations processes. Identify pain points,
                                bottlenecks, and areas for improvement. Define
                                clear goals and objectives for implementing
                                DevOps
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Create a DevOps Team
                              </h4>
                              <p className="p-0">
                                Form a cross-functional DevOps team with members
                                from development, operations, and possibly other
                                relevant departments. Assign roles and
                                responsibilities within the team
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Choose DevOps Tools
                              </h4>
                              <p className="p-0">
                                Select the right tools for your DevOps stack,
                                including those for version control, continuous
                                integration, continuous delivery,
                                containerization, automation, monitoring, and
                                more. Ensure that the chosen tools integrate
                                well with each other
                              </p>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*AI/ML */}
                <div className="chooseus-one p_relative pt_10 mt_10">
                  <div className="auto-container">
                    <div className="inner-container p_relative pb_20 mb_10">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                          <div className="content-box p_relative d_block z_1 mr-4">
                            <Fade left>
                              <div className="sec-title p_relative d_block pmtb">
                                <h2 className="p_relative d_block fs_35 fw_bold mt_20 pt_50">
                                  AI/ML
                                </h2>
                              </div>
                            </Fade>
                            <div
                              className="inner-box p_relative d_block wow fadeInUp pt_20"
                              data-wow-duration="1500ms"
                            >
                              <Slide left>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    Revolutionizing Industries with AI/ML
                                  </h4>
                                  <p className="p-0">
                                    Providing an insightful overview of AI and
                                    ML's transformative impact across various
                                    sectors, emphasizing their power to process
                                    vast datasets, automate complex tasks, and
                                    generate deep insights
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    AWS Machine Learning
                                  </h4>
                                  <p className="p-0">
                                    Exploring our use of AWS’s comprehensive
                                    suite of ML services, like Amazon SageMaker
                                    for building, training, and deploying
                                    machine learning models at scale
                                  </p>
                                </div>
                                <div className="single-item p_relative d_block pl_55 mb_16">
                                  <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                    <div className="icon p_relative d_iblock g_color">
                                      <img
                                        src={
                                          "/assets/images/img/arrow-right.png"
                                        }
                                        alt="ARROW-RIGHT"
                                        className="w_25"
                                      />
                                    </div>
                                  </div>
                                  <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                    AWS AI Services
                                  </h4>
                                  <p className="p-0">
                                    Highlighting our integration of AWS AI
                                    services such as Amazon Lex for building
                                    conversational interfaces, Amazon Polly for
                                    turning text into lifelike speech, and
                                    Amazon Rekognition for image and video
                                    analysis
                                  </p>
                                </div>
                              </Slide>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                          <Fade right>
                            <div className="image_block_two">
                              <div className="image-box p_relative d_block">
                                <figure className="image p_relative d_block">
                                  <img
                                    src={
                                      "/assets/images/img/ai.svg"
                                    }
                                    alt="MOBILE_APPLICATION"
                                    className="mob_img mt-5 pt-5"
                                  />
                                </figure>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Other Services  */}
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                      <div className="image_block_two">
                        <div className="p_relative d_block">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/services.svg"}
                              alt="WEB_CMS_ECOMMERCE"
                              className="web_img "
                            />
                          </figure>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <Fade right>
                          <div className="sec-title p_relative d_block mb_20">
                            <h2 className="p_relative d_block fs_40 fw_bold">
                              Other Services
                            </h2>
                          </div>
                        </Fade>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_10"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Consulting and Strategy
                              </h4>
                              <p className="p-0">
                                Providing expert advice and strategic guidance
                                to clients on technology decisions, project
                                planning, and digital transformation initiatives
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Quality Assurance and Testing
                              </h4>
                              <p className="p-0">
                                Conducting rigorous testing and quality
                                assurance processes to identify and resolve
                                bugs, errors, and usability issues in mobile
                                apps and web applications
                              </p>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                Maintenance and Support
                              </h4>
                              <p className="p-0">
                                Offering ongoing maintenance, updates, and
                                technical support services to ensure the
                                continued smooth operation of mobile apps and
                                web applications post-launch
                              </p>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Phone Content */}
              <div className="phone-Content">
                <div className="row align-items-center clearfix">
                  {/* Mobile App Development */}
                  <div>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Mobile App Development
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Native App Development
                                </h4>
                                <p className="p-0">
                                  Building mobile applications specifically for
                                  a single platform (iOS or Android) using
                                  platform-specific programming languages (Swift
                                  or Objective-C for iOS, Java or Kotlin for
                                  Android)
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Cross-Platform App Development
                                </h4>
                                <p className="p-0">
                                  Creating mobile apps that can run on multiple
                                  platforms using frameworks like React Native,
                                  Flutter, or Xamarin
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Hybrid App Development
                                </h4>
                                <p className="p-0">
                                  Developing apps that combine elements of both
                                  native and web applications, often using web
                                  technologies like HTML, CSS, and JavaScript
                                  wrapped in a native container
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/app_devlopment.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* Web Development */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Web Development
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Front-End Development
                                </h4>
                                <p className="p-0">
                                  Designing and building the user interface (UI)
                                  and user experience (UX) components of
                                  websites using technologies like HTML, CSS,
                                  and JavaScript
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Back-End Development
                                </h4>
                                <p className="p-0">
                                  Developing the server-side logic, databases,
                                  and APIs that power web applications, often
                                  using languages and frameworks like Node.js,
                                  Python (Django or Flask), Ruby on Rails, or
                                  PHP (Laravel or Symfony)
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Full-Stack Development
                                </h4>
                                <p className="p-0">
                                  Providing end-to-end development services,
                                  encompassing both front-end and back-end
                                  development, to create fully functional web
                                  applications
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/web_development.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* Database Management */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Database Management
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Database Design and Architecture
                                </h4>
                                <p className="p-0">
                                  Designing the structure and architecture of
                                  databases to efficiently store, organize, and
                                  retrieve data, taking into account factors
                                  like scalability, performance, and security
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Database Development
                                </h4>
                                <p className="p-0">
                                  Developing and implementing database solutions
                                  tailored to the specific needs of clients,
                                  including creating database schemas, writing
                                  SQL queries, and optimizing database
                                  performance
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Database Administration
                                </h4>
                                <p className="p-0">
                                  Managing and maintaining databases to ensure
                                  ongoing reliability, security, and
                                  performance, including tasks such as backup
                                  and recovery, data integrity, and user access
                                  control
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/server.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* Software Architecture  */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Software Architecture
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Understand the Requirements
                                </h4>
                                <p className="p-0">
                                  Begin by thoroughly understanding the
                                  functional and non-functional requirements of
                                  the software. This includes user needs,
                                  business goals, performance expectations, and
                                  scalability requirements
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Identify Key Stakeholders
                                </h4>
                                <p className="p-0">
                                  Identify and involve all relevant
                                  stakeholders, including end-users, product
                                  managers, developers, and quality assurance
                                  teams, to gather input and insights
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Define Architecture Objectives
                                </h4>
                                <p className="p-0">
                                  Establish clear architecture objectives that
                                  align with the project's goals. These
                                  objectives should guide your architectural
                                  decisions
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/software_arch.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* Dev Ops  */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Dev Ops
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Assessment and Planning
                                </h4>
                                <p className="p-0">
                                  Begin by assessing your current development
                                  and operations processes. Identify pain
                                  points, bottlenecks, and areas for
                                  improvement. Define clear goals and objectives
                                  for implementing DevOps
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Create a DevOps Team
                                </h4>
                                <p className="p-0">
                                  Form a cross-functional DevOps team with
                                  members from development, operations, and
                                  possibly other relevant departments. Assign
                                  roles and responsibilities within the team
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Choose DevOps Tools
                                </h4>
                                <p className="p-0">
                                  Select the right tools for your DevOps stack,
                                  including those for version control,
                                  continuous integration, continuous delivery,
                                  containerization, automation, monitoring, and
                                  more. Ensure that the chosen tools integrate
                                  well with each other
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/devvoops.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* AI/ML  */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              AI/ML
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Revolutionizing Industries with AI/ML
                                </h4>
                                <p className="p-0">
                                  Providing an insightful overview of AI and
                                  ML's transformative impact across various
                                  sectors, emphasizing their power to process
                                  vast datasets, automate complex tasks, and
                                  generate deep insights
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  AWS Machine Learning
                                </h4>
                                <p className="p-0">
                                  Exploring our use of AWS’s comprehensive suite
                                  of ML services, like Amazon SageMaker for
                                  building, training, and deploying machine
                                  learning models at scale
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  AWS AI Services
                                </h4>
                                <p className="p-0">
                                  Highlighting our integration of AWS AI
                                  services such as Amazon Lex for building
                                  conversational interfaces, Amazon Polly for
                                  turning text into lifelike speech, and Amazon
                                  Rekognition for image and video analysis
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/ai.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  {/* Other Services  */}
                  <div className="mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                            <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                              Other Services
                            </h2>
                          </Fade>
                        </div>

                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Consulting and Strategy
                                </h4>
                                <p className="p-0">
                                  Providing expert advice and strategic guidance
                                  to clients on technology decisions, project
                                  planning, and digital transformation
                                  initiatives
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Quality Assurance and Testing
                                </h4>
                                <p className="p-0">
                                  Conducting rigorous testing and quality
                                  assurance processes to identify and resolve
                                  bugs, errors, and usability issues in mobile
                                  apps and web applications
                                </p>
                              </div>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="ARROW-RIGHT"
                                    className="w_25"
                                  />
                                </div>
                              </div>
                              <div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Maintenance and Support
                                </h4>
                                <p className="p-0">
                                  Offering ongoing maintenance, updates, and
                                  technical support services to ensure the
                                  continued smooth operation of mobile apps and
                                  web applications post-launch
                                </p>
                              </div>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <Fade>
                        <div className="image_block_two">
                          <div className="p_relative d_block">
                            <figure className="image p_relative d_block mb-5">
                              <img
                                src={
                                  "/assets/images/img/services.svg"
                                }
                                alt="WEB_CMS_ECOMMERCE"
                                className="web_img"
                              />
                            </figure>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
