import React, { useState } from "react";
import { ConfigurationContext, ProjectContext } from "../CreateContext";
import { toast } from "react-toastify";
import { useContext } from "react";

const ProjectState = ({ children }) => {
  // const projectAPIUrl = process.env.REACT_APP_PROJECT_API_URL;
  const { configurationData } = useContext(ConfigurationContext);
  const [isLoading, setIsLoading] = useState(true);

  let projectAPIUrl;

  if (configurationData) {
    projectAPIUrl = `${configurationData.surl}/api/project`;
  } else {
    projectAPIUrl = `${process.env.REACT_APP_SERVER_API_URL}/api/project`;
  }
  const [projectData, setProjectData] = useState();

  const getAllProject = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${projectAPIUrl}/getproject`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setProjectData(data);
        setIsLoading(false);
        // console.log(data)
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error.message);
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const DefaultObj = { getAllProject, projectData, isLoading };
  return (
    <ProjectContext.Provider value={DefaultObj}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectState;
