import React from "react";
import PostionState from "./state/PostionState";
import ContactState from "./state/ContactState";
import ProjectState from "./state/ProjectState";
import ReviewState from "./state/ReviewState";
import ConfigurationState from "./state/ConfigurationState";

const MainContextProvider = ({ children }) => {
  return (
    <ConfigurationState>
      <PostionState>
        <ContactState>
          <ProjectState>
            <ReviewState>{children}</ReviewState>
          </ProjectState>
        </ContactState>
      </PostionState>
    </ConfigurationState>
  );
};

export default MainContextProvider;
