import React from "react";
import "./header/Header.css";
import Content from "./Banner_content";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import ClientLogo from "../components/Client_logo";
import { Fade, Slide } from "react-reveal";
import { Link } from "react-router-dom";

import StarBorderIcon from "@mui/icons-material/StarBorder";

const About = ({ title }) => {
  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }

  useEffect(() => {
    document.title = `${title} | ${cname} `;
  }); 

  return (
    <>
      {/* <Content
        title="Innovative Solutions for Today's Digital World"
        pathname="Crafting Unique Web & Mobile Apps for Success"
      /> */}
       <div className="boxed-wrapper">
        <div className="p_relative pt_140 pb_150 centred">
          <div className="bg-layer p_absolute parallax_none content_img"></div>
          <div className="auto-container">
            <div className="inner-box p_relative about_c">
              <Fade top>
                <h1 className="d_block fs_37 fw_bold">About PossibilityWave</h1>
              </Fade>
              <Fade bottom>
                <p className="fs_20 mt-4 content_a">
                  <Link to="/">
                    Home / Crafting Unique Web & Mobile Apps for Success
                  </Link>
                </p>
                <p className="fs_20 mt-2 content_a">
                  Crafting Unique Web & Mobile Apps for Success
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      

      <div className="boxed-wrapper">
        {/* <div className="chooseus-one p_relative">
          <div className="auto-container">
            <div className="inner-container p_relative pb_20 mb_10 imgmp_top">
              
            </div>
          </div>
        </div> */}

        {/* Service Responsive Pending */}

        <div className="deskContent">
          <div className="p_relative pb_50 mb_50">
            <div className="auto-container">
              <div className="inner-container p_relative mt-5 mx-4">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="">
                      <div className="content-box p_relative d_block z_1">
                        <Fade left>
                          <div className="sec-title p_relative d_block pt_130 mb_20">
                            <h2 className="p_relative d_block fs_40 fw_bold mt_10">
                              About Our Company
                            </h2>
                          </div>
                        </Fade>
                        <Fade left>
                          <div
                            className="p_relative d_block mb_25 wow fadeInUp"
                            data-wow-duration="1500ms"
                          >
                            <p className="fs_19">
                              Welcome to Possibility Wave, a cutting-edge mobile
                              app development company specializing in boutique
                              web and mobile applications. Our expertise lies in
                              the medical and integrative health industry,
                              finance, medical hardware and software
                              integrations and innovative gift card solutions
                              with digital wallet integration. We excel in
                              advising on database design and architecture,
                              focusing on solving intricate management systems
                              for our clients.
                            </p>
                            <p className="fs_19 mt-3">
                              What sets PossibilityWave apart?
                            </p>
                          </div>
                        </Fade>
                        <div className="inner-box p_relative d_block wow fadeInUp pt_20">
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our team of experienced and skilled developers
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our expertise in a wide range of technologies
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our commitment to innovation and creativity
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our commitment to customer service and support
                              </h4>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade right>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image_block_two">
                        <div className="p_relative d_block mt_20">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/about_our_company.png"}
                              alt="ABOUT_OUR_COMPANY"
                              className="company_img"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
                {/* <div className="row align-items-center clearfix mb_30">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/why_choose_us.svg"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt-5 pt_50">
                      <div className="content-box p_relative d_block  mt-5">
                        <Fade right>
                          <div className="sec-title p_relative d_block mb_30">
                            <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                              Why Choose Us?
                            </h2>
                          </div>
                        </Fade>
                        <Fade right>
                          <div
                            className="text p_relative d_block mb_30 wow fadeInUp"
                            data-wow-duration="1500ms"
                          >
                            <p className="fs_18">
                              At Codeash Infotech, we're committed to delivering
                              quality mobile and web solutions that help you
                              stand out from the competition with our passion
                              for creativity, innovation, and user-centered
                              design.
                            </p>
                          </div>
                        </Fade>
                        <ul
                          className="list-style-one clearfix p_relative d_block mb_30 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Transparency :</Fade>
                            <Fade bottom>
                              <p className="lh_25">
                                Complete Transparent services so you know
                                exactly what you're paying for.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Affordable :</Fade>

                            <Fade bottom>
                              <p className="lh_25">
                                Budget-friendly software development that won't
                                break the bank.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Reliable :</Fade>
                            <Fade bottom>
                              <p className="lh_25">
                                Daily meetings to keep your project on time and
                                within budget.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Agile :</Fade>
                            <Fade bottom>
                              <p className="lh_25">
                                Trusted software development experts with a
                                proven track record.
                              </p>
                            </Fade>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="funfact-one p_relative py-4 text-center">
            <div>
              <div className="p_absolute"></div>
            </div>
            <div className="auto-container">
              <div className="row clearfix justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                  <div className="counter-block-one wow">
                    <div className="inner-box p_relative d_block b_11">
                      <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                        {/* <span className="count-text">300+</span> */}
                        <div>
                          <StarBorderIcon
                            sx={{
                              color: "#0d431e",
                              background: "white",
                              borderRadius: "50px",
                              width: "50px",
                              height: "50px",
                              padding: "9px",
                            }}
                          />
                        </div>
                        <h5 className=" g_color py-3">
                          Innovative Solutions for Industries
                        </h5>
                        <p className="g_color">
                          Possibility Wave is known for its innovative approach
                          to mobile app development, specializing in crafting
                          cutting-edge solutions for the alternative health
                          industry, finance sector, and gift card solutions. Our
                          expertise in digital wallet provisioning sets us
                          apart, providing seamless user experiences and secure
                          transactions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                  <div className="counter-block-one wow">
                    <div className="inner-box p_relative d_block b_11">
                      <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                        {/* <span className="count-text">300+</span> */}
                        <div>
                          <StarBorderIcon
                            sx={{
                              color: "#0d431e",
                              background: "white",
                              borderRadius: "50px",
                              width: "50px",
                              height: "50px",
                              padding: "9px",
                            }}
                          />
                        </div>
                        <h5 className=" g_color py-3">
                          Professionalism, Creativity, and Reliability
                        </h5>
                        <p className="g_color">
                          With a strong focus on database design and
                          architecture, we excel in building robust and scalable
                          applications to meet the needs of our clients. Our
                          attention to detail ensures that complex management
                          systems are streamlined and efficient, resulting in
                          optimized performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 funfact-block">
                  <div className="counter-block-one wow">
                    <div className="inner-box p_relative d_block b_11">
                      <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                        {/* <span className="count-text">300+</span> */}
                        <div>
                          <StarBorderIcon
                            sx={{
                              color: "#0d431e",
                              background: "white",
                              borderRadius: "50px",
                              width: "50px",
                              height: "50px",
                              padding: "9px",
                            }}
                          />
                        </div>
                        <h5 className=" g_color py-3">
                          Expertise in Database Management
                        </h5>
                        <p className="g_color">
                          At Possibility Wave, professionalism, creativity, and
                          reliability are at the core of our values. We pride
                          ourselves on delivering top-notch services that exceed
                          expectations, earning us a reputation for excellence
                          in the mobile app development industry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="funfact-one p_relative py-4 text-center mt-4">
              <div>
                <div className="p_absolute"></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix justify-content-center">
                  <div className="col-md-6 col-sm-12 funfact-block pr-2">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">300+</span> */}
                          <div className="text-left">
                            <StarBorderIcon
                              sx={{
                                color: "#0d431e",
                                background: "white",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px",
                                padding: "9px",
                              }}
                            />
                          </div>
                          <h4 className=" fs_20 fw_exbold g_color text-left">
                            Gift Card Solutions with Digital Wallet Provisioning
                          </h4>
                          <p className="g_color text-left p-0">
                            Transforming gift card solutions with digital wallet
                            provisioning, we offer seamless and secure
                            transactions for your business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 funfact-block pr-2">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">300+</span> */}
                          <div className="text-left">
                            <StarBorderIcon
                              sx={{
                                color: "#0d431e",
                                background: "white",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px",
                                padding: "9px",
                              }}
                            />
                          </div>
                          <h4 className=" fs_20 fw_exbold g_color text-left">
                            Alternative Health Mobile Apps
                          </h4>
                          <p className="g_color text-left p-0">
                            Medical Mobile Apps HIPAA Compliance From finance to
                            digital wallet solutions, our team specializes in
                            developing high-quality Android and Apple
                            applications tailored to your needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 funfact-block pr-2">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">300+</span> */}
                          <div className="text-left">
                            <StarBorderIcon
                              sx={{
                                color: "#0d431e",
                                background: "white",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px",
                                padding: "9px",
                              }}
                            />
                          </div>
                          <h4 className=" fs_20 fw_exbold g_color text-left">
                            Database Design & Architecture
                          </h4>
                          <p className="g_color text-left p-0">
                            Our expertise in database design and architecture
                            ensures efficient and effective data management
                            solutions for your business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 funfact-block pr-2">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">300+</span> */}
                          <div className="text-left">
                            <StarBorderIcon
                              sx={{
                                color: "#0d431e",
                                background: "white",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px",
                                padding: "9px",
                              }}
                            />
                          </div>
                          <h4 className=" fs_20 fw_exbold g_color text-left">
                            Finance & Mobile App Development
                          </h4>
                          <p className="g_color text-left p-0">
                            With a focus on the alternative health industry, we
                            develop cutting-edge mobile apps to elevate the
                            digital presence of health and wellness businesses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Phone Content----------------- */}
        <div className="phone_Content">
          <div className="p_relative pb_10 mb_10">
            <div className="auto-container">
              <div className="inner-container p_relative">
                <div className="row align-items-center clearfix mb_30 mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            About our company
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                          Welcome to Possibility Wave, a cutting-edge mobile
                            app development company specializing in boutique web
                            and mobile applications. Our expertise lies in the
                            medical and integrative health industry, finance,
                            medical hardware and software integrations and
                            innovative gift card solutions with digital wallet
                            integration. We excel in advising on database design
                            and architecture, focusing on solving intricate
                            management systems for our clients.
                          </p>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our team of experienced and skilled developers
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our expertise in a wide range of technologies
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our commitment to innovation and creativity
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our commitment to customer service and support
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/about_our_company.png"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1 mb-5"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row align-items-center clearfix mb_30 mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            Why Choose Us?
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                            At Codeash Infotech, we're committed to delivering
                            quality mobile and web solutions that help you stand
                            out from the competition with our passion for
                            creativity, innovation, and user-centered design.
                          </p>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Budget-friendly software development that won't
                              break the bank.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Trusted software development partners who always
                              have your back.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Daily meetings to keep your project on time and
                              within budget.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Trusted software development experts with a proven
                              track record.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Transparent software development services so you
                              know exactly what you're paying for
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            Why Choose Us?
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                            At Codeash Infotech, we're committed to delivering
                            quality mobile and web solutions that help you stand
                            out from the competition with our passion for
                            creativity, innovation, and user-centered design.
                          </p>
                        </div>
                        <ul
                          className="list-style-one clearfix p_relative d_block mb_30 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Transparency :
                            <p>
                              Complete Transparent services so you know exactly
                              what you're paying for.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Affordable :
                            <p>
                              Budget-friendly software development that won't
                              break the bank.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Reliable :
                            <p>
                              Daily meetings to keep your project on time and
                              within budget.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19">
                            Agile :
                            <p>
                              Trusted software development experts with a proven
                              track record.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/why_choose_us.svg"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1 mb-5"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <ClientLogo />
      </div>
    </>
  );
};
export default About;
