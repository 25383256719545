import React from "react";
import { ConfigurationContext, ContactContext } from "../CreateContext";
import { toast } from "react-toastify";
import { useContext } from "react";

const ContactState = ({ children }) => {
  // const contactAPIUrl = process.env.REACT_APP_CONTACT_API_URL;
  const { configurationData } = useContext(ConfigurationContext);

  let contactAPIUrl;

  if (configurationData) {
    contactAPIUrl = `${configurationData.surl}/api/contact`;
  } else {
    contactAPIUrl = `${process.env.REACT_APP_SERVER_API_URL}/api/contact`;
  }
  
  const addContactReq = async ({ fname, lname, mno, email, description }) => {
    try {
      const response = await fetch(`${contactAPIUrl}/addcontact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fname, lname, mno, email, description }),
      });
      const { status, message } = await response.json();
      if (status) {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // console.log(error);
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const DefaultObj = { addContactReq };
  return (
    <ContactContext.Provider value={DefaultObj}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactState;
