import React, { useContext, useState } from "react";
import { ConfigurationContext, PositionContext } from "../CreateContext";
import { toast } from "react-toastify";

const PostionState = ({ children }) => {
  const { configurationData } = useContext(ConfigurationContext)
  const [isLoading , setIsLoading] = useState(true)

  let positionAPIUrl;

  if (configurationData) {
    positionAPIUrl = `${configurationData.surl}/api/position`;
  } else {
    positionAPIUrl = `${process.env.REACT_APP_SERVER_API_URL}/api/position`;
  }
  // const positionAPIUrl = process.env.REACT_APP_POSITION_API_URL;
  
  const [positionData, setPositionData] = useState();
  const getAllPosition = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(`${positionAPIUrl}/getposition`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setPositionData(data);
        // console.log(data)
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setIsLoading(false)
    } catch (error) {
      // console.log(error);
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const addApplicants = async (formData) => {
    try {
      const response = await fetch(`${positionAPIUrl}/addapplicants`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      });
      const { status, message } = await response.json();
      if (status) {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // console.log(error);
      toast.error("Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const DefaultObj = { getAllPosition, positionData , addApplicants , isLoading};
  return (
    <PositionContext.Provider value={DefaultObj}>
      {children}
    </PositionContext.Provider>
  );
};

export default PostionState;
