import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import MainContextProvider from "./context/MainContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <MainContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MainContextProvider>
  </>
);
