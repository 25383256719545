import React, { useContext, useEffect } from "react";
import Content from "../components/Banner_content";
import "./header/Header.css";
import { ConfigurationContext, ProjectContext } from "../context/CreateContext";
import { Fade } from "react-reveal";

const Portfolio = ({ title }) => {
  // const PublicImageURL = process.env.REACT_APP_PUBLIC_IMAGE_URL
  const { getAllProject, projectData, isLoading } = useContext(ProjectContext);
  const { configurationData } = useContext(ConfigurationContext);

  let PublicImageURL;
  let cname;

  if (configurationData) {
    PublicImageURL = `${configurationData.surl}/img`;
    cname = `${configurationData.cname}`;
  } else {
    PublicImageURL = `${process.env.REACT_APP_SERVER_API_URL}/img`;
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }

  useEffect(() => {
    document.title = `${title} | ${cname} `;
    getAllProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Content title="Portfolio" pathname="Portfolio" />
      {isLoading ? (
        <h3 className="text-center mt-5 mb-5">Loading...</h3>
      ) : projectData && projectData.length !== 0 ? (
        <div className="boxed-wrapper">
          <div className="chooseus-one p_relative">
            <div className="auto-container">
              <Fade top>
                <div className="sec-title p_relative d_block mb_15 mt_25 text-center mpt">
                  <h2 className="d_block fs_37 fw_bold mt-xl-5 p_relative pb-xl-3 pt-xl-5">
                    Our Recent Work
                  </h2>
                  <p className="d_block lh_28 mb-4">
                    Check out our recent work where we have helped companies
                    achieve their goals through innovative and customized <br />
                    solutions tailored to their specific needs and challenges
                  </p>
                </div>
              </Fade>
            </div>
          </div>
          <div className="">
            <div className="p_relative">
              <div className="auto-container">
                <div className="inner-container p_relative">
                  <div className="desk-Content mb_75">
                    {projectData &&
                      projectData.map(
                        (
                          {
                            project_name,
                            short_desc,
                            long_desc,
                            project_title,
                            img1,
                            img2,
                            play_link,
                            app_link
                          },
                          i
                        ) => {
                          return (
                            <div
                              key={i}
                              className={`row align-items-center clearfix ${
                                (i + 1) % 2 === 0 ? "flex-row-reverse" : ""
                              }`}
                            >
                              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                                <div className="image_block_two">
                                  <div className="image-box p_relative d_block pt_5 mt_5">
                                    {(i + 1) % 2 !== 0 ? (
                                      <>
                                        <figure className="image p_relative d_block">
                                          <img
                                            src={`${PublicImageURL}/project/${img2}`}
                                            alt="Small"
                                            name={img2}
                                            className="port_img1"
                                          />
                                          {/* <ImageWithFallback
                                              src={img2}
                                              className={"port_img1"}
                                            /> */}
                                        </figure>
                                        <figure className="image img-3 p_absolute b_0 d_block">
                                          <Fade right>
                                            <img
                                              src={`${PublicImageURL}/project/${img1}`}
                                              alt="Big"
                                              name={img1}
                                            />
                                          </Fade>
                                          {/* <ImageWithFallback src={img1} /> */}
                                        </figure>
                                      </>
                                    ) : (
                                      <>
                                        <figure className="image p_relative d_block">
                                          <img
                                            src={`${PublicImageURL}/project/${img2}`}
                                            alt="Big"
                                            className="port_img"
                                          />
                                        </figure>

                                        <figure className="image img-2 p_absolute b_0 d_block">
                                          <Fade left>
                                            <img
                                              src={`${PublicImageURL}/project/${img1}`}
                                              alt="Small"
                                            />
                                          </Fade>
                                        </figure>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div className="mt_40 pt_40">
                                  <div className="content-box p_relative d_block ml-5">
                                    <div className="sec-title p_relative d_block mb_10">
                                      <Fade bottom>
                                        <h2 className="p_relative d_block fs_38 fw_bold pl-3">
                                          {project_name}
                                        </h2>
                                      </Fade>
                                    </div>
                                    <div
                                      className="p_relative d_block mb_10 wow fadeInUp"
                                      data-wow-duration="1500ms"
                                    >
                                      <Fade bottom>
                                        <p className="fs_18 pt_10 text-justify">
                                          {short_desc}
                                        </p>
                                      </Fade>
                                    </div>
                                    <div
                                      className="inner-box p_relative d_block wow fadeInUp pt_20"
                                      data-wow-duration="1500ms"
                                    >
                                      <Fade bottom>
                                        <div className="single-item p_relative d_block pl_55 mb_16">
                                          <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                            <div className="icon p_relative d_iblock g_color">
                                              <img
                                                src={
                                                  "/assets/images/img/arrow-right.png"
                                                }
                                                alt="ARROW-RIGHT"
                                                className="w_25"
                                              />
                                            </div>
                                          </div>
                                          <h5 className="d_block lh_30 mb_4 fw_sbold">
                                            {project_title}
                                          </h5>
                                        </div>
                                      </Fade>
                                    </div>
                                    <div
                                      className="p_relative d_block wow fadeInUp"
                                      data-wow-duration="1500ms"
                                    >
                                      <Fade bottom>
                                        <p className="fs_18 text-justify">
                                          {long_desc}
                                        </p>
                                      </Fade>
                                    </div>

                                    <Fade bottom>
                                      <div className="justify-content-around d-flex mt-3 pt_30">
                                        { app_link &&
                                        <a
                                          href={
                                            app_link
                                          }
                                          rel="noreferrer"
                                          target="_blank"
                                          className="d-flex justify-content-end mr-4"
                                        >
                                          <img
                                            src={
                                              "/assets/images/img/appstore.png"
                                            }
                                            style={{ width: "50%" }}
                                            alt=""
                                          />
                                        </a>
                                        }
                                        {
                                          play_link &&
                                        <a href={play_link} rel="noreferrer" target="_blank">
                                          <img
                                            src={
                                              "/assets/images/img/playstore.png"
                                            }
                                            
                                            style={{ width: "50%" }}
                                            alt=""
                                          />
                                        </a>
                                        }
                                      </div>
                                    </Fade>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  {/* Phone Content */}
                  <div className="phone-Content">
                    {projectData &&
                      projectData.map(
                        (
                          {
                            project_name,
                            project_title,
                            long_desc,
                            short_desc,
                            img1,
                            img2,
                            app_link,
                            play_link
                          },
                          i
                        ) => {
                          return (
                            <div
                              className="row align-items-center clearfix"
                              key={i}
                            >
                              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div className="mt_10">
                                  <div className="content-box p_relative d_block mr-5">
                                    <div className="sec-title p_relative d_block mt_60">
                                      <Fade>
                                        <h2 className="p_relative d_block fs_38 fw_bold">
                                          {project_name}
                                        </h2>
                                      </Fade>
                                    </div>
                                    <div
                                      className="p_relative d_block mb_10 wow fadeInUp"
                                      data-wow-duration="1500ms"
                                    >
                                      <Fade>
                                        <p className="fs_18 text-justify">
                                          {short_desc}
                                        </p>
                                      </Fade>
                                    </div>
                                    <div
                                      className="inner-box p_relative d_block wow fadeInUp pt_20"
                                      data-wow-duration="1500ms"
                                    >
                                      <div className="single-item p_relative d_block text-md-center mb_16">
                                        {/* <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                                      <div className="icon p_relative d_iblock g_color">
                                                        <img
                                                          src={
                                                            "assets/images/img/arrow-right.png"
                                                          }
                                                          alt="ARROW-RIGHT"
                                                          className="w_25"
                                                        />
                                                      </div>
                                                    </div> */}
                                        <Fade>
                                          <h5 className="d_block lh_30 mb_4 fw_sbold">
                                            {project_title}
                                          </h5>
                                        </Fade>
                                      </div>
                                    </div>
                                    <Fade>
                                      <div
                                        className="p_relative d_block wow fadeInUp"
                                        data-wow-duration="1500ms"
                                      >
                                        <p className="fs_18 text-justify">
                                          {long_desc}
                                        </p>
                                      </div>
                                    </Fade>
                                  </div>
                                </div>
                              </div>
                              <Fade >
                                      <div className="justify-content-around d-flex mt-3 pt_30">
                                        {/* <div > */}
                                        <a
                                          rel="noreferrer"
                                          href={
                                            app_link
                                          }
                                          target="_blank"
                                          className="d-flex justify-content-end mr-3"
                                        >
                                          <img
                                            src={
                                              "/assets/images/img/appstore.png"
                                            }
                                            style={{ width: "50%" }}
                                            alt=""
                                          />
                                        </a>
                                        {/* </div> */}
                                        {/* <div> */}
                                        <a href={play_link} rel="noreferrer" target="_blank">
                                          <img
                                            src={
                                              "/assets/images/img/playstore.png"
                                            }
                                            
                                            style={{ width: "50%" }}
                                            alt=""
                                          />
                                        </a>
                                        {/* </div> */}
                                      </div>
                                    </Fade>
                              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                                <div className="image_block_two">
                                  <div className="image-box p_relative d_block pt_5 mt_5">
                                    <figure className="image p_relative d_block">
                                      <Fade left>
                                        <img
                                          src={`${PublicImageURL}/project/${img2}`}
                                          alt="PORTFOLIO_2"
                                          className="port_img1"
                                        />
                                      </Fade>
                                    </figure>
                                    <figure className="image img-3 p_absolute b_0 d_block">
                                      <Fade right>
                                        <img
                                          src={`${PublicImageURL}/project/${img1}`}
                                          alt="PORTFOLIO_2S"
                                        />
                                      </Fade>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3 className="text-center mt-5 mb-5">Not Active Any Project</h3>
      )}
    </>
  );
};

export default Portfolio;
