import React from "react";
import { ConfigurationContext } from "../CreateContext";
import { useState } from "react";
import { toast } from "react-toastify";

const ConfigurationState = ({ children }) => {
  const configurationUrl = `${process.env.REACT_APP_SERVER_API_URL}/configuration`;
  const [configurationData, setConfigurationData] = useState();

  const getAllConfiguration = async () => {
    try {
      const response = await fetch(`${configurationUrl}/getconfiguration`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { status, data } = await response.json();
      if (status) {
        setConfigurationData(data);
        return data;
      } else {
        toast.error(`Something Went Wrong`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      if (error) {
        toast.error("Server Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const DefaultObj = { getAllConfiguration, configurationData };
  return (
    <ConfigurationContext.Provider value={DefaultObj}>
      {children}
    </ConfigurationContext.Provider>
  );
};
export default ConfigurationState;
